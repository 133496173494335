body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  color: #495057;
  background-color: #eff3f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: .5em;
  margin-bottom: 50px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }
p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
      background-color: rgba(165, 165, 165, 0.1)
  }
  50% {
      background-color: rgba(165, 165, 165, 0.3)
  }
  100% {
      background-color: rgba(165, 165, 165, 0.1)
  }
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.customer-badge.status-qualified {
  background-color: #C8E6C9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #FFCDD2;
  color: #C63737;
}

.customer-badge.status-negotiation {
  background-color: #FEEDAF;
  color: #8A5340;
}

.customer-badge.status-new {
  background-color: #B3E5FC;
  color: #23547B;
}

.customer-badge.status-renewal {
  background-color: #ECCFFF;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #FFD8B2;
  color: #805B36;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-instock {
  background: #C8E6C9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #FFCDD2;
  color: #C63737;
}

.product-badge.status-lowstock {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.order-badge.order-delivered {
  background: #C8E6C9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #FFCDD2;
  color: #C63737;
}

.order-badge.order-pending {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge.order-returned {
  background: #ECCFFF;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: .5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: .125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: .5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width:44px;
  height:30px;
  display:inline-block;
}

img.flag {
  width:30px
}

                  
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');

.App {
  text-align: left;
  font-family: 'Kanit', sans-serif;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #FFFFFF; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Kanit', sans-serif;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-Description
{
  font-family: 'Kanit', sans-serif;
  font-size: calc(6px + 1.5vmin);
}

.Subnav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.p-column-title {
  font-size: 12px;
}

.p-buttonset .p-button {
  margin: 0;
  padding: 5px;
}